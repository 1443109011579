import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Fade } from 'react-awesome-reveal';
import {
	ColorBlock,
	Context,
	CentralTextBlock,
	FlipCard,
	Hero,
	MetaData,
	NewsCard,
	TeamBlock,
	TitleSection,
	UserContent,
} from '../components';
import Button from '../components/Button';
import UpcomingEvents from '../components/UpcomingEvents';
import useAcceleratorEvents from '../utils/hooks/useAcceleratorEvents';
import ThreeCol from '../components/ThreeCol';
import SpaceOverview from '../components/SpaceOverview';

const Accelerator = ({ pageContext: { title, acf, alumniCards, mentors } }) => {
	const { futureEvents } = useAcceleratorEvents();

	return (
		<Context.Consumer>
			{({ setModal }) => {
				return (
					<Fragment>
						<MetaData
							title={acf.meta_title || title}
							description={acf.meta_description}
						/>

						<Hero
							images={acf.header.header_images}
							title={acf.header.header_title}
							logo={acf.header.header_logo && acf.header.header_logo.source_url}
							link={acf.header.header_link}
							linkText={acf.header.header_link_text}
							linkPopup={acf.header.header_link_popup}
						/>

						{acf.grey_box.link_text && (
							<CentralTextBlock
								title={acf.grey_box.title}
								text={acf.grey_box.text}
								linkPopup={acf.grey_box.link_popup}
								after={
									<div className="button-wrapper-5-to-9">
										{acf.grey_box.link_popup ? (
											<Button
												onClick={() =>
													setModal({
														title: acf.grey_box.popup_form_title,
														text: acf.grey_box.popup_form_text,
													})
												}
											>
												{acf.grey_box.link_text}
											</Button>
										) : acf.grey_box.link.slice(0, 4) === 'http' ? (
											<Button href={acf.grey_box.link} target={'_blank'}>
												{acf.grey_box.link_text}
											</Button>
										) : (
											<Button href={acf.grey_box.link}>
												{acf.grey_box.link_text}
											</Button>
										)}
									</div>
								}
							/>
						)}

						{acf.flip_cards.cards && (
							<TitleSection
								content={
									<Fragment>
										<div className="card-row w-row">
											{acf.flip_cards.cards.map((card) => (
												<Fade>
													<div className="card-row-col1 w-col w-col-4">
														<FlipCard
															rounded
															front={() => (
																<div className="accelerator-card-content">
																	<img
																		src={card?.icon?.source_url}
																		alt={card.title}
																		width={70}
																	/>
																	<h3 className="accelerator-card-title">
																		{card.title}
																	</h3>
																</div>
															)}
															back={() => (
																<div className="accelerator-card-content-back">
																	<p className="accelerator-card-p---back">
																		{card.text}
																		<br />
																	</p>
																</div>
															)}
														/>
													</div>
												</Fade>
											))}
										</div>

										{acf.flip_cards.linkText && (
											<div className="button-wrapper">
												{acf.flip_cards.linkPopup ? (
													<Button onClick={() => setModal(true)}>
														{acf.flip_cards.linkText}
													</Button>
												) : (
													<Button href={acf.flip_cards.link}>
														{acf.flip_cards.linkText}
													</Button>
												)}
											</div>
										)}
									</Fragment>
								}
							/>
						)}

						{acf.text_sections?.map((item, i) => (
							<SpaceOverview
								reverse={!!(i % 2)}
								title={item.title}
								image={{
									source_url: item.image?.source_url,
									alt_text: item.image?.alt_text,
								}}
								text={item.text}
								key={item.title}
							/>
						))}

						{mentors && !!mentors.length && (
							<TitleSection
								title={acf.people.title}
								content={
									<Fragment>
										{acf.people.text && (
											<Fade>
												<div
													dangerouslySetInnerHTML={{
														__html: acf.people.text,
													}}
													className={'paragraph'}
												/>
											</Fade>
										)}

										<TeamBlock team={mentors} mentor />

										{acf.people.link_text && (
											<div className="button-wrapper-bottom">
												<Fade>
													{acf.people.link_popup ? (
														<Button
															onClick={() =>
																setModal({
																	title: acf.people.popup_form_title,
																	text: acf.people.popup_form_text,
																})
															}
															style={{
																marginRight: 'auto',
																marginLeft: 'auto',
															}}
														>
															Register here
														</Button>
													) : (
														<Button
															href={acf.people.link}
															style={{
																marginRight: 'auto',
																marginLeft: 'auto',
															}}
														>
															Register here
														</Button>
													)}
												</Fade>
											</div>
										)}
									</Fragment>
								}
							/>
						)}

						{alumniCards && !!alumniCards.length && (
							<TitleSection
								title={acf.alumni_title}
								color={'grey'}
								content={
									<Fragment>
										<div className="card-row w-row">
											{alumniCards.map(({ post_title, slug, excerpt, acf }) => (
												<div className="card-row-col1 w-col w-col-4" key={slug}>
													<Fade>
														<NewsCard
															title={title}
															text={excerpt}
															image={
																acf.header_image && acf.header_image.source_url
															}
															link={`/news/${slug}`}
														/>
													</Fade>
												</div>
											))}
										</div>

										{acf.alumni_link_text && (
											<div className="button-wrapper-bottom">
												<Fade>
													{acf.alumni_link_popup ? (
														<Button
															onClick={() =>
																setModal({
																	title: acf.alumni_popup_form_title,
																	text: acf.alumni_popup_form_text,
																})
															}
															style={{
																marginRight: 'auto',
																marginLeft: 'auto',
															}}
														>
															{acf.alumni_link_text}
														</Button>
													) : (
														<Button
															href={acf.alumni_link}
															style={{
																marginRight: 'auto',
																marginLeft: 'auto',
															}}
														>
															{acf.alumni_link_text}
														</Button>
													)}
												</Fade>
											</div>
										)}
									</Fragment>
								}
							/>
						)}

						{!!futureEvents.length && (
							<TitleSection
								title={acf.events_title}
								color={'grey'}
								content={
									<ThreeCol>
										{futureEvents.slice(0, 3).map((event) => {
											return (
												<Fade key={event.node.title}>
													<NewsCard
														title={event.node.title}
														text={event.node.excerpt}
														image={event?.node?.acf?.image?.source_url}
														link={`/event/${event.node.slug}`}
													/>
												</Fade>
											);
										})}
									</ThreeCol>
								}
							/>
						)}

						{acf.hs_sections && !!acf.hs_sections.length && (
							<TitleSection
								color={'grey'}
								content={
									<Fragment>
										<Fade>
											<div className="hubspot-row w-row">
												{acf.hs_image ? (
													<div className="hubspot-row-col1 w-col w-col-4 w-col-small-small-stack w-col-tiny-tiny-stack">
														<img
															src="images/HubSpotforStartups_Logo_Final.png"
															width={250}
															alt="Hubspot logo"
															className="hubspot-logo"
														/>
													</div>
												) : null}

												{acf.hs_intro ? (
													<div className="hubspot-row-col2 w-col w-col-8 w-col-small-small-stack w-col-tiny-tiny-stack">
														<div
															className="hubspot-intro-p"
															dangerouslySetInnerHTML={{
																__html: acf.hs_intro,
															}}
														/>
													</div>
												) : null}
											</div>
										</Fade>

										{acf.hs_sections.map(({ title, cards, text }) => (
											<Fragment>
												<Fade>
													<h2 className="hubspot-h2">{title}</h2>
												</Fade>

												{text && (
													<Fade>
														<UserContent content={text} />
													</Fade>
												)}

												{cards && (
													<div className="w-row">
														{cards.map(({ title, text }) => (
															<Fade>
																<div
																	className="card-row-col1 w-col w-col-4"
																	key={title}
																>
																	<FlipCard
																		front={() => (
																			<div className="team-card-content">
																				{title && (
																					<h3 className="team-name">{title}</h3>
																				)}
																			</div>
																		)}
																		back={() => (
																			<div className="team-card-content-back">
																				<div
																					className="card-p---back"
																					dangerouslySetInnerHTML={{
																						__html: text,
																					}}
																				/>
																			</div>
																		)}
																		className={'flip-card--hubspot'}
																	/>
																</div>
															</Fade>
														))}
													</div>
												)}
											</Fragment>
										))}
									</Fragment>
								}
							/>
						)}
					</Fragment>
				);
			}}
		</Context.Consumer>
	);
};

export default Accelerator;
